<template>
  <div class="min-w-max">
    <section
      class="flex justify-between py-3 text-gray-dark"
      :class="{
        'bg-white': background,
      }"
    >
      <div class="flex justify-start">
        <div class="flex items-center mr-4 px-1">
          <div class="relative inline-block w-full text-gray">
            <select
              class="w-full h-10 pl-3 pr-8 border-primary hover:shadow-primary-sm focus:ring-0 focus:shadow-primary focus:border-primary shadow-primary rounded-lg appearance-none"
              placeholder="Regular input"
              @change="updatePaging($event.target.value)"
            >
              <option
                v-for="(page, i) in paging"
                :key="i"
                :value="page"
                :selected="perPage === page"
              >
                {{ page }}
              </option>
            </select>
          </div>
        </div>
        <ul class="flex items-center">
          <li class="pr-1" v-if="hasPrev()">
            <a href="#" @click.prevent="changePage(prevPage)">
              <div
                class="flex items-center justify-center hover:bg-primary-dark hover:text-white rounded-md h-8 w-8"
              >
                <div>
                  <svg
                    class="h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M15 19l-7-7 7-7"
                    />
                  </svg>
                </div>
              </div>
            </a>
          </li>
          <li class="pr-1" v-if="hasFirst()">
            <a href="#" @click.prevent="changePage(1)">
              <div
                class="flex hover:bg-primary-dark hover:text-white rounded-md h-8 w-8 items-center justify-center"
              >
                <span> 1 </span>
              </div>
            </a>
          </li>
          <li class="pr-1" v-if="hasFirst()">...</li>
          <li class="pr-1" v-for="(page, i) in pages" :key="i">
            <a href="#" @click.prevent="changePage(page)">
              <div
                :class="{
                  'bg-primary text-white': current == page,
                }"
                class="flex hover:bg-primary-dark hover:text-white rounded-md h-8 w-8 items-center justify-center"
              >
                <span>{{ page }}</span>
              </div>
            </a>
          </li>
          <li class="pr-1" v-if="hasLast()">...</li>
          <li class="pr-1" v-if="hasLast()">
            <a href="#" @click.prevent="changePage(totalPages)">
              <div
                class="flex hover:bg-primary-dark hover:text-white rounded-md h-8 w-8 items-center justify-center"
              >
                <span>
                  {{ totalPages }}
                </span>
              </div>
            </a>
          </li>
          <li class="pr-1" v-if="hasNext()">
            <a href="#" @click.prevent="changePage(nextPage)">
              <div
                class="flex items-center justify-center hover:bg-primary-dark hover:text-white rounded-md h-8 w-8"
              >
                <div>
                  <svg
                    class="h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </div>
            </a>
          </li>
        </ul>
      </div>
      <div class="flex justify-end">
        <slot name="footer-action" />
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "PaginationGista",
  props: {
    current: {
      type: Number,
      default: 1,
    },
    total: {
      type: Number,
      default: 0,
    },
    perPage: {
      type: Number,
      default: 9,
    },
    pageRange: {
      type: Number,
      default: 2,
    },
    paging: {
      type: [Array, Function],
      default: () => [10, 25, 50, 100],
    },
    background: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      input: "",
    };
  },
  methods: {
    hasFirst() {
      return this.rangeStart !== 1;
    },
    hasLast() {
      return this.rangeEnd < this.totalPages;
    },
    hasPrev() {
      return this.current > 1;
    },
    hasNext() {
      return this.current < this.totalPages;
    },
    changePage(page) {
      if (page > 0 && page <= this.totalPages) {
        this.$emit("page-changed", page);
      }
    },
    updatePaging(page) {
      this.perPage = page;
      this.$emit("paging-change", page);
    },
  },
  computed: {
    pages() {
      let pages = [];

      for (let i = this.rangeStart; i <= this.rangeEnd; i++) {
        pages.push(i);
      }

      return pages;
    },
    rangeStart() {
      let start = this.current - this.pageRange;

      return start > 0 ? start : 1;
    },
    rangeEnd() {
      let end = this.current + this.pageRange;

      return end < this.totalPages ? end : this.totalPages;
    },
    totalPages() {
      return Math.ceil(this.total / this.perPage);
    },
    nextPage() {
      return this.current + 1;
    },
    prevPage() {
      return this.current - 1;
    },
  },
};
</script>
