var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('date-picker',{attrs:{"mode":_vm.mode,"is24hr":_vm.is24hr,"is-range":_vm.isRange,"model-config":_vm.modelConfig,"min-date":_vm.minDate == 'false' || _vm.minDate == '' ? false : new Date(_vm.minDate),"max-date":_vm.maxDate == 'false' || _vm.maxDate == '' ? false : new Date(_vm.maxDate),"value":_vm.value,"masks":{ L: 'DD/MM/YYYY' }},on:{"input":_vm.handleSelected},scopedSlots:_vm._u([{key:"default",fn:function({ inputValue, inputEvents }){return [_c('div',{staticClass:"flex flex-col"},[(_vm.label != '')?_c('div',[_c('label',{staticClass:"block pr-4 mb-1 text-sm font-medium text-gray md:text-left",class:[{ 'text-error': _vm.error }]},[_vm._v(" "+_vm._s(_vm.label)+" "),(_vm.mandatory)?_c('span',{staticClass:"text-red"},[_vm._v("*")]):_vm._e()])]):_vm._e(),_c('div',{staticClass:"relative"},[_c('input',_vm._g({staticClass:"p-2.5 border text-gray rounded-lg placeholder-gray-light focus:outline-none w-full focus:border-secondary focus:shadow-secondary-round",class:[
              {
                'border-primary hover:shadow-primary-sm': !_vm.error,
                'border-error': _vm.error,
                'pr-10': _vm.withIcon,
                'pl-2': !_vm.withIcon,
                // 'text-small': size != 'large',
                'border-none bg-gray-lightest cursor-not-allowed': _vm.disabled,
                'border-primary hover:shadow-primary-sm': !_vm.disabled,
              },
              {
                'text-small h-11': _vm.size == 'small',
                'text-base h-12': _vm.size == 'medium',
                'text-base h-14': _vm.size == 'large',
              },
            ],attrs:{"placeholder":_vm.placeholder,"disabled":_vm.disabled},domProps:{"value":inputValue}},inputEvents)),(_vm.withIcon)?_c('div',{staticClass:"absolute inset-y-0 right-0 flex items-center pr-4 icon-calendar",class:{ 'text-gray': !_vm.error, 'text-error': _vm.error },staticStyle:{"font-weight":"600"}}):_vm._e()])])]}}])}),_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }