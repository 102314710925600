var render = function render(){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"px-4 py-2 rounded-md text-xs bg-opacity-100",class:{
    'bg-primary text-white': _vm.type === 'primary',
    'bg-gray-light text-white': _vm.type === 'secondary',
    'bg-error-lightest text-error': _vm.type === 'error',
    'bg-success-lightest text-success': _vm.type === 'success',
    'bg-warning-lightest text-warning': _vm.type === 'warning',
    'bg-tertiary-lightest text-tertiary-dark': _vm.type === 'blue',
    'bg-secondary-lightest text-secondary-dark': _vm.type === 'purple',
    'bg-primary-tosca-lightest text-primary-tosca-dark': _vm.type === 'tosca',
    'bg-gray-lightest text-gray-dark': _vm.type === 'gray',
    'shadow-primary-sm': ['primary', 'danger', 'success'].includes(_vm.type),
    'shadow-primary-sm': _vm.type !== 'secondary' && _vm.type !== 'warning',
  }},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }