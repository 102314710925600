<template>
  <div class="flex flex-col">
    <div class="flex items-center">
      <label
        class="block text-sm text-gray"
        :class="{ 'text-error': error, 'mb-1': label, truncate: truncateLabel }"
      >
        {{ label }}
      </label>
    </div>
    <div
      class="flex flex-row items-center pl-0 border rounded-lg border-primary focus:border-secondary focus:shadow-secondary-round"
      :class="{ 'border-gray-lightest': disabled, 'border-error': error }"
    >
      <div class="flex w-full">
        <date-picker
          v-model="inputDateRange"
          mode="date"
          :masks="masks"
          @input="handleInput"
          is-range
          class="w-full"
        >
          <template v-slot="{ inputValue, inputEvents }">
            <div class="flex items-center w-full">
              <input
                class="h-12 p-2.5 pr-0 border-none rounded-lg focus:outline-none placeholder-gray-light w-full text-gray"
                :class="{
                  'bg-gray-lightest border-gray-lightest': disabled,
                  'border-error': error,
                }"
                :value="
                  startDate != ''
                    ? inputValue.start + ' ~ ' + inputValue.end
                    : null
                "
                :placeholder="placeholder"
                :disabled="disabled"
                v-on="inputEvents.start"
                readonly
              />
              <span @click="clearDate" v-if="clearable" class="cursor-pointer">
                <svg
                  v-if="!disabled && !isValueEmpty"
                  viewBox="0 0 512 512"
                  xmlns="http://www.w3.org/2000/svg"
                  class="w-6 my-2 mr-2 pointer-events-none"
                  width="20"
                  height="20"
                >
                  <path
                    fill="#666666"
                    d="M175 175C184.4 165.7 199.6 165.7 208.1 175L255.1 222.1L303 175C312.4 165.7 327.6 165.7 336.1 175C346.3 184.4 346.3 199.6 336.1 208.1L289.9 255.1L336.1 303C346.3 312.4 346.3 327.6 336.1 336.1C327.6 346.3 312.4 346.3 303 336.1L255.1 289.9L208.1 336.1C199.6 346.3 184.4 346.3 175 336.1C165.7 327.6 165.7 312.4 175 303L222.1 255.1L175 208.1C165.7 199.6 165.7 184.4 175 175V175zM512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 48C141.1 48 48 141.1 48 256C48 370.9 141.1 464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48z"
                  />
                </svg>
              </span>
              <svg
                v-if="!disabled"
                class="w-6 my-2 mr-2 text-gray-600 pointer-events-none"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19 4H5C3.89543 4 3 4.89543 3 6V20C3 21.1046 3.89543 22 5 22H19C20.1046 22 21 21.1046 21 20V6C21 4.89543 20.1046 4 19 4Z"
                  stroke="#666666"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M16 2V6"
                  stroke="#666666"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M8 2V6"
                  stroke="#666666"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M3 10H21"
                  stroke="#666666"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </template>
        </date-picker>
      </div>
    </div>
    <div
      v-if="this.$slots['message'] != undefined"
      class="mt-1 text-xs"
      :class="[{ 'text-error': error }]"
    >
      <slot name="message"></slot>
    </div>
  </div>
</template>

<script>
import DatePicker from "v-calendar/src/components/DatePicker";
export default {
  name: "DatepickerRangeGista",
  components: {
    DatePicker,
  },
  data() {
    return {
      dateRange: { start: "", end: "" },
      masks: {
        input: "DD/MM/YYYY",
      },
    };
  },
  props: {
    minDate: {
      type: String,
      default: "",
    },
    maxDate: {
      type: String,
      default: "",
    },
    startDate: {
      type: String,
      default: "",
    },
    endDate: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "Select a date range",
    },
    label: {
      type: String,
      default: "",
    },
    format: {
      type: String,
      default: "DD/MM/YYYY",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    inputDateRange: {
      default: {
        start: "",
        end: "",
      },
    },
    error: {
      // error flag
      type: Boolean,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    handleInput(value) {
      this.$emit("input", value);
    },
    clearDate() {
      this.$emit("clear");
    },
  },
  computed: {
    isValueEmpty() {
      return (
        !this.inputDateRange ||
        this.inputDateRange?.start.length < 1 ||
        this.inputDateRange?.end.length < 1
      );
    },
  },
};
</script>
