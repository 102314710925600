<template>
  <div>
    <div class="flex items-center">
      <label
        class="block font-medium text-sm text-gray mb-1"
        :class="{ 'text-error': error }"
      >
        {{ label }} <span v-if="mandatory" class="text-error">*</span>
      </label>
    </div>
    <textarea
      v-bind="$attrs"
      :disabled="$attrs.disabled"
      v-on="listeners"
      :value="value"
      class="border border-primary rounded-md p-4 w-full focus:outline-none placeholder-gray-light"
      :class="{
        'border-primary': !error,
        'border-error': error,
        'bg-gray-lightest border-none': disabled,
      }"
    >
    </textarea>
    <div
      v-if="this.$slots['message'] != undefined"
      class="text-xs"
      :class="[{ 'text-error': error }]"
    >
      <slot name="message"></slot>
    </div>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,

  props: {
    label: String,
    error: Boolean,
    disabled: Boolean,
    value: String,
    mandatory: Boolean,
  },

  name: "TextareaGista",

  computed: {
    listeners() {
      return {
        ...this.$listeners,
        input: (event) => this.$emit("input", event.target.value),
      };
    },
  },

  methods: {
    handleInput(e) {
      this.$emit("input", e.target.value);
    },
  },
};
</script>
