var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fixed h-screen p-5 shadow-primary-lg",class:[
    {
      'left-0': _vm.position === 'left',
      'right-0': _vm.position === 'right',
      'bg-white': !_vm.customClass,
    },
    _vm.computedWidth,
    _vm.customClass,
  ]},[_vm._t("content")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }