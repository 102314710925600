var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex items-center my-2"},[_c('input',{staticClass:"checked: focus:ring-white rounded-md",class:{
      'bg-gray-lightest border-gray-lightest text-gray-lightest cursor-not-allowed':
        _vm.disabled || _vm.readOnly,
      'h-6 w-6 text-base': _vm.size == 'md',
      'h-8 w-8 text-lg': _vm.size == 'lg',
      'h-10 w-10 text-xl': _vm.size == 'xl',
      'border border-primary hover:shadow-primary-sm cursor-pointer text-primary':
        !_vm.disabled || !_vm.readOnly,
    },attrs:{"type":"checkbox","disabled":_vm.disabled || _vm.readOnly,"true-value":_vm.trueValue,"false-value":_vm.falseValue,"readonly":_vm.readOnly},domProps:{"checked":_vm.isChecked},on:{"change":function($event){!_vm.readOnly && _vm.updateInput($event)}}}),_c('span',{staticClass:"ml-2",class:{
      'text-base': _vm.size == 'md',
      'text-lg': _vm.size == 'lg',
      'text-xl': _vm.size == 'xl',
    }},[_vm._t("default")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }